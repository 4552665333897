
//==========  Colors  ==========//

// set our colour variables
// avoid using obvious declarative names as the colours may not always match the name
// e.g, $colour-orange
// 
// we also create lighter and darker tones for use with hover states, etc.

$color-primary: 		#97c93d; 						// the brand colour
$color-primary-light:	lighten($color-primary, 5%);	// the brand colour - lighter
$color-primary-dark:	darken($color-primary, 5%);		// the brand colour - darker

$color-secondary: 		#f78e20; 						// the accent colour
$color-secondary-light:	lighten($color-secondary, 5%);	// the accent colour - lighter
$color-secondary-dark:	#f26724;	// the accent colour - darker


//==========  Tones  ==========//

// set grey tones variables
// these will typically be used for borders and form inputs
// again, we also create lighter and darker tones for use with hover states, etc.

$color-lightgrey:		#d9dadb;							// a default light grey
$color-lightgrey-light:	#f4f4f4;	// a default light grey - lighter
$color-lightgrey-dark:	darken($color-lightgrey, 5%);	// a default light grey - darker

$color-midgrey:			#b3b2b3;	 						// a default mid grey
$color-midgrey-light:	lighten($color-midgrey, 5%);	// a default mid grey - lighter
$color-midgrey-dark:	darken($color-midgrey, 5%);		// a default mid grey - darker

$color-darkgrey:		#55565a;						// a default dark grey
$color-darkgrey-light:	lighten($color-darkgrey, 5%);	// a default dark grey - lighter
$color-darkgrey-dark:	#3b3e41;	// a default dark grey - darker

$color-white: 			#fff;							// a default white
$color-black: 			#010101;							// a default black
$color-black-light: 	#26282a;							// a default black
$color-black-dark:      #000;

//==========  Text colors  ==========//

// set our default text colours for titles, paragraphs, etc.

$color-text:	#55565a;								// body text colour
$color-sub:		$color-text;								// sub and intro text colour
$color-title:	$color-text;							// heading text colour


