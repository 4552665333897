
// Clear paragraph styles
p {
	margin: 0;
}

// Reset strong tags
strong {
	font-weight: $font-bold;
}

// Reset hr tags 
hr {
	border: $border;
}

// Blockquote
blockquote {
	margin: 0;
	padding: 0;
	font-size: $font-size-body + 0.5;
	font-family: $font-family-title;
	font-weight: $font-normal;
}
