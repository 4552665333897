
// Content styles
.centre .content {
	
	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		&.intro {
			padding-bottom: $paragraph-margin;
			border-bottom: $border;
			color: $color-sub;
			font-family: $font-family-sub;
			font-size: $font-size-body + 0.2;
			font-weight: $font-light;
		}
	}
	
	// New default <ul> lists
	* + ul {
		@extend %default-ul;
	}
	
	// New default <ol> lists
	* + ol {
		@extend %default-ol;
	}
}

