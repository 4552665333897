
// Default .button class
.button {
    @extend %button;
    display: inline-block;
    padding: 0.5rem 3rem;
    border-radius: $border-radius;
    transition: $transition;

    &:hover {
        @extend %button-hover;
    }

    a {
        color: inherit;
        transition: inherit;
    }
}

.read-more {
    text-transform: lowercase;
    font-family: $font-family-title;
    color: $color-secondary;
    font-weight: $font-bold;

    &:hover {
        color: $color-primary;
    }
    
}
